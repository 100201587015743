import { ReactElement, useEffect, useState, useRef } from 'react';
import { Multiselect } from 'multiselect-react-dropdown';
import { useQuery } from '@apollo/client';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik } from 'formik';
import { allOrienteers } from '../../queries';
import { getDate, noTime } from '../../utils/utilsDate';
import { GET_ALL_ORIENTED_WITH_USER } from '../../queries/Oriented';
import TittleInProgress from '../UI/TittleInProgress';
import CustomDatePicker from '../UI/CustomDatePicker';
import { halfHourlyRange } from '../../utils/utilsDate';
import { GET_ALL_PROGRAMS } from '../../queries/Program';
import timeSpanOptions from '../../utils/timeSpanOptions'

const AdminAppointmentDetail = ({ appointment, back }): ReactElement => {
  const appointmentId = appointment ? appointment.id : null;
  const [startDate, setDate] = useState(new Date());
  const [programs, setPrograms] = useState<any>([]);
  const [time, setTime]= useState([] as string[]);
  const [submitOriented, setsubmitOriented] = useState([]);
  const [submitOrienteer, setsubmitOrienteer] = useState([]);
  const [submitProgram, setSubmitProgram] = useState(appointment.program.id);
  const [submitName, setsubmitName] = useState('');
  const componentMounted = useRef(true);
  const optionsOrientedInitialState = {
    value: '',
    label: '',
    programs:['']
  }
  const optionsOrienteerInitialSate = {
    value: '',
    label: ''
  }
  
  const [optionsOriented, setOptionsOriented] = useState([optionsOrientedInitialState]);
  const [options, setOptions] = useState([optionsOrienteerInitialSate]);
  const [optionsOrientedFiltered, setOptionsOrientedFiltered] = useState<any>([]);
  const {
    data: dataOriented,
    loading: loadingOriented,
    refetch: refetchOriented,
  } = useQuery(GET_ALL_ORIENTED_WITH_USER);
  const { data: allProgramsData, loading: loadingPrograms } = useQuery(GET_ALL_PROGRAMS)

  useEffect(()=>{
    if (!submitProgram) {setSubmitProgram (appointment.program.id)}
    const filteredProgramOrienteds = optionsOriented.filter((option)=>{return option.programs.includes(submitProgram)})
    setOptionsOrientedFiltered(filteredProgramOrienteds)
    
  },[submitProgram, optionsOriented])

  useEffect(()=>{
    if (!loadingPrograms && allProgramsData) {
      setPrograms(allProgramsData.getAllPrograms);
    }
  })

  useEffect(() => {
    if (componentMounted.current) {
      const formatterorienteer = appointment.orienteersDropdown.map((orienteer) => {
        return {
          label: orienteer.name,
          value: appointment.orienteerId,
        };
      });
      const formatteroriented = appointment.orientedsDropdown.map((oriented) => {
        return {
          label: oriented.user.name,
          value: oriented.id,
          programs: oriented.paymentPlans.map((program)=>{return program.programSlug})
        };
      });
      setDate(new Date(appointment.date))
      setsubmitOrienteer(formatterorienteer);
      setsubmitOriented(formatteroriented);
      setsubmitName(appointment.name);
    }
    return ():void => { 
      componentMounted.current = false; 
    }
  }, []);

  useEffect(() => {
    if (componentMounted.current){
      allOrienteers().then((res) => {
        const formatter = res.data.allOrienteer.map((orienter) => {
          return {
            label: orienter.user.name,
            value: orienter.id,
          };
        });
        setOptions(formatter);
      });
      refetchOriented()
    }
  }, []);

  useEffect(() => {
    if (componentMounted.current){
      if (!loadingOriented && !!dataOriented) {
        const formatter = dataOriented.getAllOrientedsWithUser.map((oriented) => {
          return {
            label: oriented.user.name,
            value: oriented.id,
            programs: oriented.paymentPlans.map((program)=>{return program.programSlug})
          };
        });
        timeHandler(new Date())
        setOptionsOriented(formatter);
      }
    }
  }, [dataOriented]);

  
  const timeHandler = (newDate) : void => {
    let finalTimeArr : string[] = []
    const timeOpt : string[] = halfHourlyRange(8,21)
    const today : Date = new Date()
    const validation : Date = newDate || startDate
    if (noTime(validation) <= noTime(today)) {
      let hoursArr: string[] =[]
      const hour :number = today.getHours()
      const index : number = timeOpt.indexOf(hour.toString()+':00')
      const plus30 : boolean = validation.getMinutes()>=30
      if (index>=0){
        hoursArr = plus30 ? timeOpt.slice(index+2): timeOpt.slice(index+1)
      }else{
        hour < 9 ? hoursArr = timeOpt : hoursArr=[]
      }
      finalTimeArr = hoursArr.map((i)=> i)
    }else{
      finalTimeArr = timeOpt.map((i)=> i)
    }
    setTime(finalTimeArr)
  }
  const selectDateHandler = (pickedDate): void => {
    setDate(pickedDate);
    timeHandler(pickedDate);
  };

  return (
    <div className="bg-white min-h-screen my-8">
      <div className="container mx-auto">
        <div className="inputs w-full max-w-6xl">
          <TittleInProgress text={'Información sobre el encuentro'} width={'w-3/5'} />
          <h1 className="text-lgs text-black-primary text-left mt-4">
            Detalle sobre programa, orientadores y orientados participantes, fecha, horario y duración estimada del encuentro.
          </h1>
          <Formik
            enableReinitialize
            initialValues={
              {
                id: appointmentId,
                orienteds: submitOriented,
                name: submitName,
                orienteers: submitOrienteer,
                date: getDate(startDate),
                time: appointment.time,
                timeSpan: appointment.timeSpan,
                detail: appointment.detail,
                isReprogramable: false,
                programSlug: submitProgram
              }
            }
            onSubmit={async(values):Promise<void> => {
              values;}}
          >
            {({
              values,
              handleChange,
            }) :ReactElement => (
              <form className="mt-2 pt-4">
                <div className="flex flex-wrap">
                  <div className="personal w-11/12 mt-10">
                    <h2 className="text-base text-black-primary text-left font-bold">
                      01. Informacion sobre el tipo de programa
                    </h2>
                    <div className="flex items-center mt-4">
                      <div className="w-full md:w-1/3 flex justify-start flex-col">
                        <label className="label-form">Programa</label>
                        <div className="flex-shrink w-full inline-block relative">
                          <select
                            id="programSlug"
                            value={values.programSlug}
                            disabled={true}
                            onChange={(e) : void => {
                              handleChange(e);
                              setSubmitProgram(e.target.value)
                            }}
                            className="block appearance-none text-black-primary w-full bg-white border border-gray-secundary px-3 py-3 rounded-md focus:outline-none"
                          >
                            <option value={''}>
                              Programa...
                            </option>
                            {programs &&
                              programs.map((i, index) => (
                                <option key={index} value={i.id}>
                                  {i.name}
                                </option>
                              ))}
                          </select>
                          <div className="pointer-events-none absolute top-0 mt-3  right-0 flex items-center px-2 text-gray-600">
                            <svg
                              className="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="w-full border-t border-gray-secundary mt-10 mb-10"></div>
                    <h2 className="text-base text-black-primary text-left font-bold">
                      02. Informacion sobre el evento
                    </h2>
                    <div className="flex items-center mt-4">
                      <div className="w-11/12 md:w-3/4 flex justify-start flex-col">
                        <label className="block tracking-wide text-black-primary text-xs mb-2 text-left">
                          Nombre del evento
                        </label>
                        <input
                          onChange={(event)=>{setsubmitName(event?.target.value)}}
                          disabled={true}
                          type="text"
                          id="name"
                          value={submitName}
                          name="name"
                          placeholder="Ingresar nombre"
                          className=" block w-full bg-white text-black-primary border border-gray-secundary hover:border-green-principal rounded-md px-2 pt-3 pb-3  leading-tight"
                        />
                      </div>
                      <div className="w-full md:w-3/4 px-3 flex justify-start flex-col">
                        <label className="block tracking-wide text-black-primary text-xs mb-2 text-left">
                          Orientador/es participante/s
                        </label>
                        <Multiselect
                          value={values.orienteers}
                          id="orienteers"
                          displayValue="label"
                          loading={options[0].label !='' ? false : true }
                          loadingMessage={'cargando...'}
                          options={options}
                          disablePreSelectedValues={true}
                          disable={true}
                          showCheckbox={true}
                          showArrow={true}
                          placeholder="Seleccionar orientador"
                          selectedValues={appointmentId ? submitOrienteer:''}
                          style={{
                            searchBox: {
                              border: '1px solid #DADEEB',
                              padding: '8px 0px 8px 12px'
                            },
                            chips: {
                              background: '#F4F7FB',
                              color: '#0BA4A0',
                            },
                            option: {
                              background: '#FFFFFF',
                              color: '#0BA4A0',
                            },
                          }}
                        />
                      </div>
                      <div className="w-full md:w-3/4 px-3 flex justify-start flex-col">
                        <label className="block tracking-wide text-black-primary text-xs mb-2 text-left">
                          Orientado/s participante/s
                        </label>
                        {
                          <Multiselect
                            value={values.orienteds}
                            disabled={true}
                            displayValue="label"
                            showCheckbox={true}
                            disablePreSelectedValues={true}
                            disable={true}
                            showArrow={true}
                            loading={optionsOriented.length !==0 ? false : true }
                            loadingMessage={'cargando...'}
                            options={optionsOrientedFiltered}
                            placeholder={'Seleccione orientado'}
                            selectedValues={submitOriented}
                            id="orienteds"
                            style={{
                              searchBox: {
                                border: '1px solid #DADEEB',
                                padding: '8px 0px 8px 12px'
                              },
                              chips: {
                                background: '#F4F7FB',
                                color: '#0BA4A0',
                              },
                              option: {
                                background: '#FFFFFF',
                                color: '#0BA4A0',
                              },
                            }}
                          />
                        }
                      </div>
                    </div>

                    <div className="w-full border-t border-gray-secundary mt-10 mb-10"></div>

                    <h2 className="text-base text-black-primary text-left font-bold">
                      03. Dias y horarios disponibles
                    </h2>
                    <div className="flex items-center  mt-4">
                      <div className="w-full md:w-1/3 flex justify-start flex-col">
                        <label className="block tracking-wide text-black-primary text-xs mb-2 text-left">
                          Fecha
                        </label>
                        <CustomDatePicker
                          value={startDate}
                          placeholder="Ingresar fecha"
                          setValue={selectDateHandler}
                          minDateToday
                        />
                      </div>
                      <div className="w-full md:w-1/3 px-3 flex justify-start flex-col">
                        <label className="block tracking-wide text-black-primary text-xs mb-2 text-left">
                          Horario
                        </label>
                        <div className="flex-shrink w-full inline-block relative">
                          <select
                            disabled={true}
                            id="time"
                            value={values.time}
                            onChange={handleChange}
                            className="block appearance-none text-black-principal w-full bg-white border border-gray-secundary hover:border-green-principal px-3 py-3 rounded-md"
                          >
                            
                            {time.map((appointmentTime, index)=> {
                              return <option key={index} value={appointmentTime}>{appointmentTime}</option>
                            })}
                          </select>
                          <div className="pointer-events-none absolute top-0 mt-3  right-0 flex items-center px-2 text-gray-600">
                            <svg
                              className="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="w-full md:w-1/3 px-3 flex justify-start flex-col">
                        <label className="block tracking-wide text-black-primary text-xs mb-2 text-left">
                          Duración estimada
                        </label>
                        <div className="flex-shrink w-full inline-block relative has-tooltip">
                          <select
                            id="timeSpan"
                            value={values.timeSpan}
                            disabled={true}
                            onChange={handleChange}
                            className="has-tooltip block appearance-none text-black-principal w-full bg-white border border-gray-primary px-3 py-3 rounded-md"
                          >
                            {timeSpanOptions.map((i, index) => (
                              <option key={index} value={i.value}>
                                {i.label}
                              </option>
                            ))}
                          </select>
                          <div className="pointer-events-none absolute top-0 mt-3  right-0 flex items-center px-2 text-gray-600">
                            <svg
                              className="fill-current h-4 w-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          </div>
                          <div className="italic tooltip bottom-20">
                            La duración o el tiempo estimado dependerá de la
                            dinámica y tipo de encuentro
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="personal w-full border-t border-gray-secundary mt-10 pt-10">
                      <h2 className="text-base text-black-primary text-left font-bold mb-4">
                        04. Detalle
                      </h2>
                      <div className="w-full md:w-2/3 mb-6 flex justify-start flex-col">
                        <label className="block tracking-wide text-black-primary text-xs mb-2 text-left">
                          Comentarios del evento{' '}
                        </label>
                        <textarea
                          disabled={true}
                          id="detail"
                          value={values.detail}
                          className="rounded-md border text-black-principal leading-normal resize-none w-full h-40 py-3 px-3 border border-gray-secundary hover:border-green-principal font-medium placeholder-gray-secundary focus:outline-none focus:bg-white"
                          placeholder="Escribe un comentario.."
                        ></textarea>
                      </div>
                    </div>
                    <a className="cancel-link" onClick={():void => back()}>
                      Volver atrás
                    </a>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AdminAppointmentDetail;
